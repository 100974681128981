/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "caption_colors_default": "text-black",
  "caption_size_default": "w-full col-span-12 text-body-sm",
  "caption_variant_default": "",
  "footer_colors_default": "",
  "footer_size_default": "w-full col-span-12 [&:not(:first-child)]:mt-12",
  "footer_variant_default": "flex items-center justify-center",
  "headline_colors_default": "text-black after:bg-black",
  "headline_size_default": "w-full col-span-12 text-headline-sm sm:text-headline after:h-0.25",
  "headline_variant_default": "",
  "headline_variant_decorated": "flex items-center gap-x-3 after:block after:flex-grow",
  "colors_default": "",
  "size_default": "w-full max-w-screen-xl mx-auto container-col-sm-12:gap-x-2 gap-y-8 md:gap-y-2",
  "size_alternative": "w-full max-w-screen-xl mx-auto gap-x-2 gap-y-8 md:gap-y-2 py-4",
  "variant_default": "relative grid grid-cols-12 auto-rows-min group/row empty:hidden [&:not(:has(:not(.box:empty)))]:hidden [&>*:not(.grid-cols-12)]:col-span-full"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Row");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;